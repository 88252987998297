import React from "react";
import messages from "../../../assets/local/ar.json";
import { Row, Col } from "reactstrap";
import { featuresImg } from "../../../config/landing-page-imgs";
import "./features.scss";
import FeaturesCard from "./FeaturesCard/FeaturesCard";
const Features = () => {
  return (
    <section className="container section features-wrapper mb-0 pb-5" id="features">
      <Row className="features-header mx-0">
        <h4 className="section-title features-header_title">
          {messages.LANDING_PAGE.FEATURES.TITLE}
        </h4>
        <p className="features-header_description">
          {messages.LANDING_PAGE.FEATURES.DESCRIPTION}
        </p>
      </Row>
      <Row className="features-content mx-0">
        <Col md="5" className="features-content_img-wrapper">
          <img src={featuresImg} alt="features" />
        </Col>
        <Col md="7" className="features-content_details-wrapper">
          <Row>
            <Col md="6">
              <FeaturesCard
                header={"01"}
                title={messages.LANDING_PAGE.FEATURES.SECTION1.TITLE}
                description={
                  messages.LANDING_PAGE.FEATURES.SECTION1.DESCRIPTION
                }
                classes={"section1"}
              />
            </Col>
            <Col md="6">
              <FeaturesCard
                header={"02"}
                title={messages.LANDING_PAGE.FEATURES.SECTION2.TITLE}
                description={
                  messages.LANDING_PAGE.FEATURES.SECTION2.DESCRIPTION
                }
                classes={"section2"}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FeaturesCard
                header={"03"}
                title={messages.LANDING_PAGE.FEATURES.SECTION3.TITLE}
                description={
                  messages.LANDING_PAGE.FEATURES.SECTION3.DESCRIPTION
                }
                classes={"section3"}
              />
            </Col>
            <Col md="6">
              <FeaturesCard
                header={"04"}
                title={messages.LANDING_PAGE.FEATURES.SECTION4.TITLE}
                description={
                  messages.LANDING_PAGE.FEATURES.SECTION4.DESCRIPTION
                }
                classes={"section4"}
              />
            </Col>
            <Col md="6">
              <FeaturesCard
                  header={"05"}
                  title={messages.LANDING_PAGE.FEATURES.SECTION5.TITLE}
                  description={
                    messages.LANDING_PAGE.FEATURES.SECTION5.DESCRIPTION
                  }
                  classes={"section5"}
              />
            </Col>
            <Col md="6">
              <FeaturesCard
                  header={"06"}
                  title={messages.LANDING_PAGE.FEATURES.SECTION6.TITLE}
                  description={
                    messages.LANDING_PAGE.FEATURES.SECTION6.DESCRIPTION
                  }
                  classes={"section6"}
              />
            </Col>
            <Col md="6">
              <FeaturesCard
                  header={"07"}
                  title={messages.LANDING_PAGE.FEATURES.SECTION7.TITLE}
                  description={
                    messages.LANDING_PAGE.FEATURES.SECTION7.DESCRIPTION
                  }
                  classes={"section7"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default Features
