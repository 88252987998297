import React from "react";
import { Instagram, Twitter, Linkedin } from "react-feather";
import { Col, Row } from "reactstrap";
import { logo } from "../../../config/landing-page-imgs";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_img"></div>
      <Row className="footer_content mx-0">
        <Col md="4" className="footer_content_col">
          <a
            href="https://www.linkedin.com/in/ratbli-app/"
            target="_blanck"
            className="footer_content_col_social-link"
          >
            <Linkedin />
          </a>
          <a
            href="https://twitter.com/Ratb__li"
            className="footer_content_col_social-link"
            target="_blanck"
          >
            <Twitter />
          </a>
          <a
            href="https://www.instagram.com/ratbli/"
            target="_blanck"
            className="footer_content_col_social-link"
          >
            <Instagram />
          </a>
          {/* <a href="#" className="footer_content_col_social-link">
            <img src={googleplus} alt="G+" />
          </a> */}
        </Col>
        <Col md="4" className="footer_content_col flex-column">
          <p>
            Copyright ©2022{" "}
            <a href="http://fe-dev.ratbli.click/en/home">Ratbli</a>
          </p>
          <p>Designed with ❤ in Riyadh</p>
        </Col>
        <Col md="4" className="footer_content_col">
          <img src={logo} alt="logo" className="footer_content_logo" />
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
