import React, { useState } from "react";
import messages from "../../../assets/local/ar.json";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Col, Input, Label } from "reactstrap";
import { Row } from "reactstrap";
import { logo, emtpyImgBg } from "../../../config/landing-page-imgs";
import "./signup.scss";
import { registerLanding } from "../../../apis/landing-page";
import Swal from "sweetalert2";

const SignupForm = (props) => {
  const { open, setOpen } = props;
  const [storeImg, setStoreImg] = useState();
  const initialValues = {
    name: "",
    email: "",
    company_name: "",
    phone: "",
    store_main_picture: "",
    store_name_ar: "",
    store_name_en: "",
    accept_privacy: false,
  };
  const validationSchema = yup.object({
    name: yup
      .string()
      .min(2, messages.ERRORS.MIN_CHAR_2)
      .max(100, messages.ERRORS.MAX_CHAR_100)
      .required(messages.ERRORS.REQUIRED),
    email: yup
      .string()
      .email(messages.ERRORS.INVALID_EMAIL)
      .required(messages.ERRORS.REQUIRED),
    password: yup.string().required("REQUIRED"),
    phone: yup
      .string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        messages.ERRORS.INVALID_PHONE_NUMBER
      )
      .required(messages.ERRORS.REQUIRED),
    store_main_picture: yup.mixed().required(messages.ERRORS.REQUIRED),
    store_name_ar: yup
      .string()
      .min(2, messages.ERRORS.MIN_CHAR_2)
      .max(100, messages.ERRORS.MAX_CHAR_100)
      .required(messages.ERRORS.REQUIRED),
    store_name_en: yup
      .string()
      .min(2, messages.ERRORS.MIN_CHAR_2)
      .max(100, messages.ERRORS.MAX_CHAR_100)
      .required(messages.ERRORS.REQUIRED),
    accept_privacy: yup.boolean().required(messages.ERRORS.REQUIRED),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      registerLanding(values)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "تم التسجيل بنجاح",
            text: "برجاء الانتظار لحين مراجعة طلبك وسيتم التواصل معكم في أقرب وقت",
            timer: 2500,
          });
          setOpen(!open);
        })
        .catch((err) => {
          console.log(JSON.stringify(err.response.data.message))
          Swal.fire({
            icon: "error",
            title: "فشلت عملية التسجيل",
            text: JSON.stringify(err.response?.data?.message),
          });
          setOpen(open);
        });
    },
  });

  const goLoginPage = () => {
    window.open(`https://provider.ratbli.sa/en/login`, "_blanck");
  };
  return (
    <section className="container signup-wrapper">
      <header className="signup-wrapper_header">
        <img src={logo} alt="logo" className="signup-wrapper_header_img" />
        <h3 className="signup-wrapper_header_title text-center">
          {messages.LANDING_PAGE.SIGN_UP.WELCOME} &#128075;
        </h3>
        <h4 className="signup-wrapper_header_sub-title">
          {messages.LANDING_PAGE.SIGN_UP.REGISTER}
        </h4>
      </header>
      <form
        onSubmit={formik.handleSubmit}
        className="signup-wrapper_form container"
      >
        <section className="container signup-wrapper_form_section">
          <h5 className="signup-wrapper_form_section_title">
            {messages.LANDING_PAGE.SIGN_UP.SP_INFO}
          </h5>
          <Row className="w-100">
            <Col md="6" className="signup-wrapper_form_group">
              <Label for="name" className="signup-wrapper_form_group_label">
                {messages.LANDING_PAGE.CONTACT_US.FORM.NAME}
              </Label>
              <Input
                id="name"
                name="name"
                placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.NAME}
                className="signup-wrapper_form_group_input"
                value={formik.values.name}
                onChange={formik.handleChange}
                type="text"
              />
              {formik.errors.name && (
                <small className="text-danger">{formik.errors.name}</small>
              )}
            </Col>
            <Col md="6" className="signup-wrapper_form_group">
              <Label for="phone" className="signup-wrapper_form_group_label">
                {messages.LANDING_PAGE.CONTACT_US.FORM.PHONE}
              </Label>
              <Input
                id="phone"
                name="phone"
                placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.PHONE}
                className="signup-wrapper_form_group_input"
                value={formik.values.phone}
                onChange={formik.handleChange}
                type="text"
              />
              {formik.errors.phone && (
                <small className="text-danger">{formik.errors.phone}</small>
              )}
            </Col>
          </Row>
          <Row className="w-100">
            <Col md="6" className="signup-wrapper_form_group">
              <Label for="email" className="signup-wrapper_form_group_label">
                {messages.LANDING_PAGE.CONTACT_US.FORM.EMAIL}
              </Label>
              <Input
                id="email"
                name="email"
                placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.EMAIL}
                className="signup-wrapper_form_group_input"
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
              />
              {formik.errors.email && (
                <small className="text-danger">{formik.errors.email}</small>
              )}
            </Col>
            <Col md="6" className="signup-wrapper_form_group">
              <Label for="password" className="signup-wrapper_form_group_label">
                {messages.GENERAL.PASSWORD}
              </Label>
              <Input
                id="password"
                name="password"
                placeholder={messages.GENERAL.PASSWORD}
                className="signup-wrapper_form_group_input"
                value={formik.values.password}
                onChange={formik.handleChange}
                type="password"
              />
              {formik.errors.password && (
                <small className="text-danger">{formik.errors.password}</small>
              )}
            </Col>
          </Row>
        </section>
        <section className="container signup-wrapper_form_section">
          <h5 className="signup-wrapper_form_section_title">
            {messages.LANDING_PAGE.SIGN_UP.STORE_INFO}
          </h5>
          <Row className="w-100 mb-2">
            <div className="col-2">
              <img
                src={storeImg || emtpyImgBg}
                alt="store"
                className="signup-wrapper_form_section_img"
              />
            </div>
            <div className="col-10 signup-wrapper_form_section_uploader">
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={(e) => {
                  setStoreImg(URL?.createObjectURL(e.target.files[0]));

                  formik.setFieldValue("store_main_picture", e.target.files[0]);
                  if (e.target.files[0]?.size / 1000 > 800) {
                    formik.setFieldError("size", "large file size");
                  }
                }}
                className="d-none"
              />
              <label
                htmlFor="contained-button-file"
                className="signup-wrapper_form_section_uploader_label"
              >
                {messages.BUTTONS.UPLOAD}
              </label>
              <small>Allowed JPG, GIF or PNG. Max size of 800kB</small>
              {formik.errors.store_main_picture && (
                <small className="text-danger">
                  {formik.errors.store_main_picture}
                </small>
              )}
            </div>
          </Row>
          <Row className="w-100">
            <Col md="6" className="signup-wrapper_form_group">
              <Label
                for="store_name_ar"
                className="signup-wrapper_form_group_label"
              >
                {messages.LANDING_PAGE.SIGN_UP.STORE_NAME_AR}
              </Label>
              <Input
                id="store_name_ar"
                name="store_name_ar"
                placeholder={messages.LANDING_PAGE.SIGN_UP.STORE_NAME_AR}
                className="signup-wrapper_form_group_input"
                value={formik.values.store_name_ar}
                onChange={formik.handleChange}
                type="text"
              />
              {formik.errors.store_name_ar && (
                <p className="text-danger">{formik.errors.store_name_ar}</p>
              )}
            </Col>
            <Col md="6" className="signup-wrapper_form_group">
              <Label
                for="store_name_en"
                className="signup-wrapper_form_group_label"
              >
                {messages.LANDING_PAGE.SIGN_UP.STORE_NAME_EN}
              </Label>
              <Input
                id="store_name_en"
                name="store_name_en"
                placeholder={messages.LANDING_PAGE.SIGN_UP.STORE_NAME_EN}
                className="signup-wrapper_form_group_input"
                value={formik.values.store_name_en}
                onChange={formik.handleChange}
                type="text"
              />
              {formik.errors.store_name_en && (
                <small className="text-danger">
                  {formik.errors.store_name_en}
                </small>
              )}
            </Col>
          </Row>
        </section>
        <Row className="terms">
          <Input
            type="checkbox"
            name="accept_privacy"
            value={formik.values.accept_privacy}
            onChange={formik.handleChange}
          />

          <p>
            i agree to{" "}
            <a href="#" className="text-primary">
              privacy policy & terms
            </a>
          </p>
        </Row>
        <Button
          type="submit"
          className="signup-wrapper_form_btn"
          disabled={!formik.values.accept_privacy}
        >
          {messages.LANDING_PAGE.CONTACT_US.FORM.SEND}
        </Button>
        <Row className="terms mt-2">
          <p>
            Already have an account?{" "}
            <span onClick={goLoginPage} className="text-primary signinlink">
              Sign in instead
            </span>
          </p>
        </Row>
      </form>
    </section>
  );
};

export default SignupForm;
