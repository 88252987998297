import React from "react";
import { useState } from "react";
import messages from "../../../assets/local/ar.json";
import { Modal, ModalBody } from "reactstrap";

import { heroImage } from "../../../config/landing-page-imgs";
import FeedbackForm from "../FeedbackForm/FeedbackForm";
import "./companies.scss";
const Companies = () => {
  const [open, setOpen] = useState(false);

  return (
    <section className="container section default-section-wrapper" id="companies">
      <section className="row align-items-center">
        <div className="col-md-7 default-section-wrapper_hero">
          <img
            className="default-section-wrapper_hero_img"
            src={heroImage}
            alt="hero"
          />
        </div>
        <div className="default-section-wrapper_content col-md-5 px-4">
          <h3 className="section-title">
            {messages.LANDING_PAGE.COMPANIES.TITLE}
          </h3>
          <p className="default-section-wrapper_content_body">
            {messages.LANDING_PAGE.COMPANIES.BODY}
          </p>
          <button className="btn gradient-btn" onClick={() => setOpen(true)}>
            {messages.LANDING_PAGE.NAV.CONTACT_US}
          </button>
        </div>
      </section>
      <Modal isOpen={open} size="lg" toggle={() => setOpen(!open)}>
        <ModalBody>
          <FeedbackForm open={open} setOpen={setOpen} />
        </ModalBody>
      </Modal>
    </section>
  );
};

export default Companies
