import "./App.scss";
import LandingPage from "./pages/LandingPage";
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import PolicyAr from "./pages/LandingPage/Policy/PolicyAr";
import DeleteAccount from "./pages/DeleteAccount/DeleteAccount";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={LandingPage}/>
                <Route path="/privacy" component={PolicyAr}/>
                <Route path="/delete_account" component={DeleteAccount}/>
            </Switch>
        </Router>
    );
}

export default App;
