import React, {useState} from "react";
import messages from "../../../assets/local/ar.json";
import {Collapse, Modal, ModalBody, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,} from "reactstrap";
import {appStore, googleApp, heroImage, logo,} from "../../../config/landing-page-imgs";
import Signup from "../SignupForm/Signup";

import {Link} from "react-router-dom";

import "./header.scss";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="container banner section">
      <Navbar expand="md" className="nav-wrapper flex-row-reverse">
        <NavbarBrand href="/" className="nav-wrapper_brand">
          <img src={logo} alt="logo" />
        </NavbarBrand>
        <NavbarToggler onClick={() => setOpen(!open)} />
        <Collapse isOpen={open} navbar>
          <Nav className="nav-wrapper_ul" navbar>
            <NavItem className="nav-wrapper_ul_item">
              <NavLink className="nav-wrapper_ul_item_link" href="#companies">
                {messages.LANDING_PAGE.NAV.RATBLI_FOR_COMPANIES}
              </NavLink>
            </NavItem>

            <NavItem className="nav-wrapper_ul_item">
              <NavLink className="nav-wrapper_ul_item_link" href="#overview">
                {messages.LANDING_PAGE.NAV.SUMMARY}
              </NavLink>
            </NavItem>
            <NavItem className="nav-wrapper_ul_item">
              <NavLink className="nav-wrapper_ul_item_link" href="#about">
                {messages.LANDING_PAGE.NAV.ABOUT_RATBLI}
              </NavLink>
            </NavItem>
            <NavItem className="nav-wrapper_ul_item">
              <NavLink className="nav-wrapper_ul_item_link" href="#features">
                {messages.LANDING_PAGE.NAV.APP_BENEFITS}
              </NavLink>
            </NavItem>
            <NavItem className="nav-wrapper_ul_item">
              <NavLink className="nav-wrapper_ul_item_link">
                <Link style={{ color: "inherit" }} to="/privacy">
                  {" "}
                  {messages.LANDING_PAGE.NAV.PRIVACY}{" "}
                </Link>
              </NavLink>
            </NavItem>
            <NavItem className="nav-wrapper_ul_item">
              <NavLink className="nav-wrapper_ul_item_link" href="#contact-us">
                {messages.LANDING_PAGE.NAV.CONTACT_US}
              </NavLink>
            </NavItem>
            <NavItem className="nav-wrapper_ul_sign-up gradient-btn">
              <button
                className="btn text-light"
                onClick={() => setOpenModal(true)}
              >
                {messages.LANDING_PAGE.NAV.SERVICE_PROVIDER_SIGN_UP}
              </button>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <header className="header-wrapper row">
        <div className="header-wrapper_content col-md-6 px-4">
          <p className="header-wrapper_content_get-app">
            {messages.LANDING_PAGE.HEADER.GET_APP}
          </p>
          <h1 className="header-wrapper_content_rtbli">
            {messages.LANDING_PAGE.HEADER.RATBLI}
          </h1>
          <p className="mb-2">{messages.LANDING_PAGE.HEADER.DESCRIPTION}</p>
          <div className="header-wrapper_content_download">
            <a
              className="header-wrapper_content_download_btn"
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/us/app/ratb-li/id1308032045?ls=1"
            >
              <img src={appStore} alt="app store" />
            </a>
            <a
              className="header-wrapper_content_download_btn"
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.ITSmart.part"
            >
              <img src={googleApp} alt="google app" />
            </a>
          </div>
        </div>
        <div className="col-md-6 header-wrapper_hero">
          <img className="header-wrapper_hero_img" src={heroImage} alt="hero" />
        </div>
      </header>
      <Modal
        isOpen={openModal}
        size="lg"
        toggle={() => setOpenModal(!openModal)}
      >
        <ModalBody>
          <Signup open={openModal} setOpen={setOpenModal} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Header;
