import React from "react";

const DeleteAccount = () => {
    return (
        <div className="landing-page-wrapper" style={{direction: 'ltr', height: '100vh'}}>
            <section
                className="policy-wrapper container section default-section-wrapper">
                <div className="container">
                    <h1 className="mb-4 text-center section-title">
                        Delete Your Account
                    </h1>
                    <p>To delete your account from the Ratbli Mobile Application, follow these steps:</p>
                    <ol>
                        <li>Open the Ratbli Application and log in to your account.</li>
                        <li>Go to the "Account" tab screen.</li>
                        <li>Select the "Delete My Account" option.</li>
                        <li>Confirm the account deletion from the alert dialog.</li>
                    </ol>
                </div>
            </section>
        </div>
    );
}

export default DeleteAccount;