import axios from "axios";
// import { requestHandler, successHandler } from "./interceptors";

//add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Handle request process
// axiosInstance.interceptors.request.use((request) => requestHandler(request));
// // Handle response process
// axiosInstance.interceptors.response.use(
//   (response) => successHandler(response),
//   (error) => console.error(error)
// );
