import React from "react";
import messages from "../../../assets/local/ar.json";
import Policy from "./Policy";
import "./style.scss";
import "../styles.scss";
const PolicyAr = () => {
  return (
    <div className="landing-page-wrapper">
      <section
        className="policy-wrapper text-right container section default-section-wrapper"
        id="privacy"
      >
        <h1 className="mb-4 text-center section-title">
          سياسة الخصوصية لراتبلي
        </h1>
        <section className="row">
          <div className=" col-md-11 m-auto ">
            <h3 className="section-title">
              {messages.PRIVACY_POLICY.COLLECTING}
            </h3>

            <hr />
            <div>
              <h1 className="mt-2">{messages.PRIVACY_POLICY.TYPES}</h1>
              <h2 className="mt-4">{messages.PRIVACY_POLICY.CO}</h2>
              <h3>الداتا الشحضية</h3>
              <p className="body">
                أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا ببعض معلومات التعريف
                الشخصية التي يمكن استخدامها للاتصال أو عرف عن نفسك. قد تتضمن
                معلومات التعريف الشخصية ، ولكن لا يقتصر على:
              </p>
              <ul className="px-3 mx-3">
                <li>
                  <p>رقم التليفون</p>
                </li>
                <li>
                  <p>
                    العنوان ، الولاية ، المقاطعة ، الرمز البريدي / الرمز البريدي
                    ، المدينة
                  </p>
                </li>
                <li>
                  <p>بيانات الاستخدام</p>
                </li>
              </ul>
            </div>

            <h3 className="mt-3">بيانات الاستخدام</h3>

            <p className="body">
              يتم جمع بيانات الاستخدام تلقائيًا عند استخدام الخدمة.
            </p>
            <p className="body">
              قد تتضمن بيانات الاستخدام معلومات مثل الإنترنت الخاص بجهازك عنوان
              البروتوكول (مثل عنوان IP) ، ونوع المتصفح ، وإصدار المتصفح ، صفحات
              خدمتنا التي تزورها ، ووقت وتاريخ الزيارة ، والوقت الذي يقضيه في
              تلك الصفحات ، ومعرفات الجهاز الفريدة وبيانات التشخيص الأخرى.
            </p>
            <p className="body">
              عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول ، يجوز لنا ذلك
              جمع معلومات معينة تلقائيًا ، بما في ذلك ، على سبيل المثال لا الحصر
              يقتصر على نوع الجهاز المحمول الذي تستخدمه ، جهازك المحمول معرف
              فريد ، عنوان IP لجهازك المحمول ، هاتفك المحمول نظام التشغيل ، نوع
              متصفح الإنترنت على الهاتف المحمول الذي تستخدمه ، معرّفات الجهاز
              الفريدة وبيانات التشخيص الأخرى.
            </p>
            <p className="body">
              قد نقوم أيضًا بجمع المعلومات التي يرسلها متصفحك متى شئت أنت تزور
              خدمتنا أو عندما تصل إلى الخدمة عن طريق أو من خلال جهاز محمول.
            </p>
            <h3 className="mt-3">
              المعلومات التي تم جمعها أثناء استخدام التطبيق
            </h3>
            <p className="mt-2 body">
              أثناء استخدام تطبيقنا ، من أجل توفير ميزات التطبيق ، قد نجمع ،
              بإذن مسبق منك:
            </p>
            <ul>
              <li>معلومات بخصوص موقعك : </li>
              <p className="body">
                نستخدم هذه المعلومات لتقديم ميزات خدمتنا إلى تحسين خدماتنا
                وتخصيصها. قد يتم تحميل المعلومات إلى خوادم الشركة و / أو خادم
                مزود الخدمة أو ذلك قد يتم تخزينها ببساطة على جهازك.
              </p>
              <p className="body">
                يمكنك تمكين أو تعطيل الوصول إلى هذه المعلومات في أي وقت ، من
                خلال إعدادات جهازك.
              </p>
            </ul>

            <h2>استخدام بياناتك الشخصية</h2>
            <p className="body">
              يجوز للشركة استخدام البيانات الشخصية للأغراض التالية:
            </p>
            <ul>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    لتقديم خدماتنا والحفاظ عليها
                  </span>
                  , متضمنا بما في ذلك مراقبة استخدام خدمتنا.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">لإدارة حسابك: </span>
                  لإدارة تسجيلك كمستخدم للخدمة. يمكن أن تمنحك البيانات الشخصية
                  التي تقدمها إمكانية الوصول إلى الوظائف المختلفة للخدمة المتاحة
                  لك كمستخدم مسجل.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">لتنفيذ العقد:</span>
                  التطوير والامتثال والتعهد بعملية الشراء عقد للمنتجات أو
                  العناصر أو الخدمات التي لديك تم شراؤها أو أي عقد آخر معنا من
                  خلال خدمة.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">للاتصال بك:</span>{" "}
                  للاتصال بك عن طريق البريد الإلكتروني أو المكالمات الهاتفية أو
                  الرسائل النصية القصيرة أو غيرها من أشكال الاتصالات الإلكترونية
                  المماثلة ، مثل الإخطارات الفورية لتطبيقات الهاتف المحمول فيما
                  يتعلق بالتحديثات أو الاتصالات الإعلامية المتعلقة بالوظائف أو
                  المنتجات أو الخدمات المتعاقد عليها ، بما في ذلك التحديثات
                  الأمنية ، عند الضرورة أو المعقول لتنفيذها.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">لنوفر لك: </span> مع
                  الأخبار والعروض الخاصة و معلومات عامة عن السلع والخدمات
                  والأحداث الأخرى التي نقدمها مماثلة لتلك التي لديك بالفعل
                  اشتريت أو استفسرت عما لم تكن قد اخترت عدم القيام بذلك تلقي هذه
                  المعلومات.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">لإدارة طلباتك:</span>
                  لحضور وإدارة طلباتك إلينا.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">لنقل الأعمال:</span> قد
                  نستخدم الخاص بك معلومات لتقييم أو إجراء الاندماج أو التجريد ،
                  إعادة الهيكلة أو إعادة التنظيم أو حل أو بيع أخرى أو نقل بعض أو
                  كل أصولنا ، سواء كان ذلك قيد التنفيذ القلق أو كجزء من الإفلاس
                  أو التصفية أو ما شابه ذلك ، حيث البيانات الشخصية التي نحتفظ
                  بها حولنا مستخدمو الخدمة من بين الأصول المنقولة.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">لأغراض أخرى</span>: قد
                  نستخدم الخاص بك معلومات لأغراض أخرى ، مثل تحليل البيانات ،
                  تحديد اتجاهات الاستخدام ، وتحديد فعالية الحملات الترويجية
                  ولتقييم خدمتنا وتحسينها ، المنتجات والخدمات والتسويق وتجربتك.
                </p>
              </li>
            </ul>
            <h2>قد نشارك معلوماتك الشخصية في ما يلي مواقف:</h2>
            <ul>
              <li>
                <span className="features-card_title">مع مزودي الخدمة:</span> قد
                نشارك بك المعلومات الشخصية مع مزودي الخدمة لمراقبة و تحليل
                استخدام خدمتنا ، للاتصال بك.
              </li>
              <li>
                <span className="features-card_title">لنقل الأعمال:</span> يجوز
                لنا مشاركة أو نقل معلوماتك الشخصية فيما يتعلق أو أثناء مفاوضات ،
                أي اندماج ، بيع أصول الشركة ، تمويل ، أو الاستحواذ على كل أو جزء
                من أعمالنا إلى شخص آخر شركة.
              </li>
              <li>
                <span className="features-card_title">
                  مع المسوقين بالعمولة:
                </span>
                قد نشارك معلوماتك مع الشركات التابعة لنا ، وفي هذه الحالة سنطلب
                ذلك الشركات التابعة لاحترام سياسة الخصوصية هذه. تشمل الشركات
                التابعة لنا الشركة الأم وأي شركات تابعة أخرى ، مشروع مشترك
                الشركاء أو الشركات الأخرى التي نسيطر عليها أو التي تخضع لها
                السيطرة المشتركة معنا.
              </li>
              <li>
                <span className="features-card_title">مع شركاء الأعمال:</span>{" "}
                قد نشارك بك المعلومات مع شركائنا في العمل لنقدم لك بعض المعلومات
                المنتجات أو الخدمات أو العروض الترويجية.
              </li>
              <li>
                <span className="features-card_title">مع مستخدمين آخرين:</span>{" "}
                عندما تشارك الشخصية المعلومات أو التفاعل بطريقة أخرى في الأماكن
                العامة مع الآخرين المستخدمين ، قد يتم عرض هذه المعلومات من قبل
                جميع المستخدمين وقد يتم عرضها وزعت علنا في الخارج.
              </li>
              <li>
                <span className="features-card_title">بموافقتك</span>: قد نكشف
                عن المعلومات الشخصية لأي غرض آخر بموافقتك.
              </li>
            </ul>
            <h2 className="mt-1">الاحتفاظ ببياناتك الشخصية</h2>
            <p className="m-1 body">
              ستحتفظ الشركة ببياناتك الشخصية فقط طالما هي ضرورية للأغراض المنصوص
              عليها في سياسة الخصوصية هذه. سنقوم الاحتفاظ ببياناتك الشخصية
              واستخدامها بالقدر اللازم الامتثال لالتزاماتنا القانونية (على سبيل
              المثال ، إذا لزم الأمر للاحتفاظ ببياناتك للامتثال للقوانين المعمول
              بها) ، حل النزاعات ، وتنفيذ اتفاقياتنا وسياساتنا القانونية.
            </p>
            <p className="m-1 body">
              ستحتفظ الشركة أيضًا ببيانات الاستخدام للتحليل الداخلي المقاصد. يتم
              الاحتفاظ ببيانات الاستخدام بشكل عام لفترة أقصر تبلغ الوقت ، إلا
              عندما يتم استخدام هذه البيانات لتعزيز الأمان أو لتحسين وظائف
              خدمتنا ، أو نحن قانونيًا ملزم بالاحتفاظ بهذه البيانات لفترات زمنية
              أطول.
            </p>
            <h2>نقل بياناتك الشخصية</h2>
            <p className="m-1 body">
              تتم معالجة معلوماتك ، بما في ذلك البيانات الشخصية ، على مكاتب
              تشغيل الشركة وفي أي أماكن أخرى حيث تقع الأطراف المشاركة في
              المعالجة. هذا يعني أن هذا يمكن نقل المعلومات إلى أجهزة الكمبيوتر
              والاحتفاظ بها عليها يقع خارج ولايتك أو مقاطعتك أو بلدك أو غير ذلك
              الولاية القضائية الحكومية حيث يجوز لقوانين حماية البيانات تختلف عن
              تلك الموجودة في ولايتك القضائية.
            </p>
            <p className="m-1 body">
              موافقتك على سياسة الخصوصية هذه متبوعة بتقديمك لـ هذه المعلومات
              تمثل موافقتك على هذا النقل.
            </p>
            <p className="body">
              ستتخذ الشركة جميع الخطوات اللازمة بشكل معقول لضمان ذلك أن يتم
              التعامل مع بياناتك بشكل آمن ووفقًا لهذا سياسة الخصوصية ولن يتم نقل
              بياناتك الشخصية مكان لمنظمة أو دولة ما لم يكن هناك ما يكفي الضوابط
              المعمول بها بما في ذلك أمان بياناتك وغيرها معلومات شخصية.
            </p>
            <h2>احذف بياناتك الشخصية</h2>
            <p className="body">
              لديك الحق في حذف أو طلب مساعدتنا في الحذف البيانات الشخصية التي
              جمعناها عنك.
            </p>
            <p className="body">
              قد تمنحك خدمتنا القدرة على حذف معلومات معينة عنك من داخل الخدمة.
            </p>
            <p className="body">
              يمكنك تحديث أو تعديل أو حذف معلوماتك في أي وقت عن طريق تسجيل
              الدخول إلى حسابك ، إذا كان لديك واحد ، وزيارة قسم إعدادات الحساب
              الذي يسمح لك بإدارة ملفاتك الشخصية معلومة. يمكنك أيضًا الاتصال بنا
              لطلب الوصول إلى ، تصحيح أو حذف أي معلومات شخصية قدمتها لنا.
            </p>
            <p className="body">
              يرجى ملاحظة ، مع ذلك ، أننا قد نحتاج إلى الاحتفاظ ببعض المعلومات
              عندما يكون لدينا التزام قانوني أو أساس قانوني للقيام به لذا.
            </p>
            <h2>الإفصاح عن بياناتك الشخصية</h2>
            <h3>المعاملات التجارية</h3>
            <p className="body">
              إذا كانت الشركة متورطة في عملية اندماج أو استحواذ أو بيع أصول ، قد
              يتم نقل بياناتك الشخصية. سوف نقدم إشعار قبل أن يتم نقل بياناتك
              الشخصية وتصبح خاضعة لـ سياسة خصوصية مختلفة.
            </p>
            <h3>تطبيق القانون</h3>
            <p className="body">
              في ظل ظروف معينة ، قد يُطلب من الشركة القيام بذلك الكشف عن بياناتك
              الشخصية إذا طُلب منك ذلك بموجب القانون أو في الاستجابة للطلبات
              الصحيحة من قبل السلطات العامة (على سبيل المثال ، محكمة أو وكالة
              حكومية).
            </p>
            <h3>المتطلبات القانونية الأخرى</h3>
            <p className="body">
              قد تفصح الشركة عن بياناتك الشخصية بحسن نية الاعتقاد بأن مثل هذا
              الإجراء ضروري من أجل:
            </p>
            <ul>
              <li>الامتثال لالتزام قانوني</li>
              <li>حماية والدفاع عن حقوق أو ممتلكات الشركة</li>
              <li>منع أو التحقيق في أي مخالفات محتملة تتعلق بـ الخدمة</li>
              <li>حماية السلامة الشخصية لمستخدمي الخدمة أو عامة</li>
              <li>الحماية من المسؤولية القانونية</li>
            </ul>
            <h2>أمن بياناتك الشخصية</h2>
            <p className="body">
              أمان بياناتك الشخصية مهم بالنسبة لنا ، ولكن تذكر أنه لا توجد طريقة
              للإرسال عبر الإنترنت ، أو طريقة التخزين الإلكتروني آمنة بنسبة
              100٪. بينما نسعى جاهدين ل استخدام وسائل مقبولة تجاريًا لحماية
              بياناتك الشخصية ، لا يمكننا ضمان أمنها المطلق.
            </p>
            <h2>خصوصية الأطفال</h2>
            <p className="body">
              لا تخاطب خدمتنا أي شخص دون سن 13 عامًا جمع معلومات التعريف الشخصية
              عن عمد من أي شخص تحت سن 13 عامًا. إذا كنت أحد الوالدين أو وصيًا
              وأنت علمًا أن طفلك قد زودنا ببيانات شخصية ، من فضلك اتصل بنا. إذا
              علمنا أننا قد جمعنا الشخصية بيانات من أي شخص دون سن 13 عامًا دون
              التحقق من موافقة الوالدين ، نتخذ خطوات لإزالة هذه المعلومات من
              خوادمنا.
            </p>
            <p className="body">
              إذا احتجنا إلى الاعتماد على الموافقة كأساس قانوني لمعالجة ملف
              المعلومات وبلدك يتطلب موافقة أحد الوالدين ، نحن قد تتطلب موافقة
              والديك قبل أن نجمعها ونستخدمها معلومة.
            </p>
            <h3>روابط لمواقع أخرى</h3>
            <p className="body">
              قد تحتوي خدمتنا على روابط لمواقع أخرى ليست كذلك التي تديرها لنا.
              إذا قمت بالنقر فوق ارتباط جهة خارجية ، فستكون كذلك موجهة إلى موقع
              الطرف الثالث. ننصحك بشدة أن مراجعة سياسة الخصوصية لكل موقع تزوره.
            </p>
            <p className="body">
              ليس لدينا سيطرة ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية
              أو الممارسات الخاصة بأي مواقع أو مواقع خاصة بطرف ثالث خدمات.
            </p>
            <h2>التغييرات على سياسة الخصوصية هذه</h2>
            <p className="body">
              قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. سنقوم بإخطار
              لك أي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.
            </p>
            <p className="body">
              سنخبرك عبر البريد الإلكتروني و / أو إشعار بارز على موقعنا الخدمة ،
              قبل أن يصبح التغيير ساريًا
            </p>
            <p className="body">
              يُنصح بمراجعة سياسة الخصوصية هذه بشكل دوري لأي التغييرات. تكون
              التغييرات التي تطرأ على سياسة الخصوصية هذه فعالة عندما تكون كذلك
              يتم نشرها على هذه الصفحة.
            </p>
            <h2>اتصل بنا</h2>
            <p className="body">
              إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه ، يمكنك ذلك اتصل بنا:
            </p>
            <ul>
              <li>
                <p>
                  من خلال زيارة هذه الصفحة على موقعنا:{" "}
                  <a
                    href="https://www.ratbli.sa/"
                    rel="external nofollow noopener"
                    target="_blank"
                  >
                    https://www.ratbli.sa/
                  </a>
                </p>
              </li>
              <li>
                <p>عن طريق رقم الهاتف: 966 0544374444</p>
              </li>
            </ul>
            <h2 className="mt-2">شروط مقدِمي الخدمة</h2>
            <div className="condition-text">
              <p className="body">
                لتحقيق رغبتكم في الانضمام إلى منصة رتب لي وعرض خدماتكم عبرها
                يتطلب الأمر منكم الموافقة على اشتراطات المنصة، وأنت بمجرد
                استخدامك للمنصة عبر التطبيق أو المتصفح أو أي وسيلة أخرى فأنت
                توافق على هذه الشروط، كما أن استخدامك لمنصة رتب لي في كل مرة
                يكون وفقا للشروط والالتزامات المطبقة حينها.
              </p>
              <p className="body">
                كما أنه من المفترض توافر الأهلية القانونية في جميع المستخدمين،
                ولا يُسمح بالاستخدام لمن لم تتوفر فيه الأهلية القانونية ما لم
                يكن هذا الاستخدام تحت مسؤولية شخص مؤهل للتعاقد.
              </p>
              <p className="body">
                وأنت تؤكد أن استخدامك لمنصة رتب لي سيكون في إطار للغرض العام
                الذي من أجله أنشئت هذه المنصة وأن استخدامك سيكون وفقا للغرض
                المحدد لكل مستخدم دون نسخ أو اقتباس أو إعادة استخدام ودون أي
                منافسة أو عرض عام بأي شكل من الأشكال لأيٍّ من أفكار أو محتويات
                المنصة ما لم تكن هناك موافقة خطية مسبقة.
              </p>
              <p className="body">
                كما أنك تؤكد أنه لم يسبق حظرك من قِبل منصة رتب لي عبر أي تسجيل
                سابق لأي سبب كان.
              </p>
              <p className="body">
                كما تؤكد أن كافة المعلومات المُدخلة من قِبلك صحيحة ودقيقة وخاصة
                بك، وتتعهد بالمحافظة على المُدخلات والأرقام السرية، وتؤكد تحمُلك
                المسؤولية عن أي دخول أو استخدام يجري من خلال المعرّف الخاص بك.
              </p>
              <p className="body">
                مع بذلنا العناية الإلكترونية اللازمة للحماية إلا أن منصة رتب لي
                لا تتحمل أي مسؤولية عن الأخطاء أو الأضرار الناتجة بسبب خدمة
                الإنترنت، بما في ذلك أعطال الشبكة أو الفيروسات وعمليات الاختراق.
              </p>
              <p className="body">
                تخلي منصة رتب لي مسؤوليتها عن أي صفحة خارجية تؤدي إليها الروابط
                التي قد يضيفها أحد مستخدمي المنصة لأي غرض كان.
              </p>
              <p className="body">
                تتعامل &nbsp;منصة رتب لي مع عملائها على أساس من المهنية
                والخصوصية، ويتطلب العمل متابعة تسجيل العميل في كل مرة وحفظ ملفات
                الارتباط (الكوكيز) على سبيل المثال.
              </p>
              <p className="body">
                هذه الشروط خاصة بين المنصة وعملائها ولا يحق لأي طرف خارج هذه
                العلاقة الاستفادة منها، عدا حالة انتقال ملكية نشاطك إلى مالك آخر
                بشرط إبلاغ منصة رتب لي عن أي تغيرات تتصل بالملكية.
              </p>
              <p className="body">
                كما يحق لمنصة رتب لي نقل كافة الحقوق والالتزامات إلى أي طرف أو
                أطراف أخرى.
              </p>
              <p className="body">
                مع سياسة المصداقية والشفافية التي تنتهجها منصة رتب لي مع عملائها
                إلا أنه لا يتوجب عليها إبداء الأسباب التي تحملها على رفض تسجيلك
                أو طلبك أو إيقاف حسابك لأسباب تعود إلى تقييم السوق أو مقدمي
                الخدمة أو المستفيدين منها.
              </p>
              <p className="body">
                يكون الاختصاص المكاني لأي دعوى مرتبطة بعمل منصة رتب لي في مدينة
                الرياض، وبشرط إبلاغ المنصة عن الرغبة في رفع الدعوى ضدها بما لا
                يقل عن عشرة أيام، وتعتبر أي دعوى لا تتقيد بهذا الشرط ساقطة.
              </p>
              <p className="body">
                كما أنك بتعاملك عبر أو مع منصة رتب لي تتعهد بتقديم المعلومات
                والبيانات والوثائق المرتبطة بإثبات هويتك أو الخدمة أو المنتج
                الذي تقدمه عند طلبه منك في أي وقت من الأوقات.
              </p>
              <p className="body">
                كما أنك توافق على أحقية منصة رتب لي في متابعة سير العمل بأي
                وسيلة مشروعة وممكنة سواء بالاتصال المباشر مع أطراف العملية أو
                عبر البريد أو الرسائل أو متابعة خط السير عبر الأقمار الصناعية.
              </p>
              <p className="body">
                كما أنك تؤكد على أن جميع البيانات والصور والعروض المرفوعة على
                منصة رتب لي من قِبلك كلها مطابقة للواقع وللخدمات المقدّمة، وتؤكد
                أنها غير محظورة شرعا ولا نظاما وأنها من تأليفك [على سبيل المثال:
                تصويرك] الشخصي وليست مقتبسة، وليست متعلقة بأي حقوق فكرية أخرى.
              </p>
              <p className="body">
                الملكية الفكرية لمنصة رتب لي تمتد لأي بيانات أو صور أو عرض يتم
                عبر المنصة، وبالتالي لها حق إعادة الاستخدام لجميع ما هو معروض
                عليها دون أي التزام ينشأ عن ذلك الاستخدام، ولها حق مطالبة الغير
                عن أي انتهاك يقع على محتوياتها.
              </p>
              <p className="body">
                وأنت على علم بأن علاقتك التعاقدية وما ينشأ عنها من حقوق
                والتزامات تكون تجاه طالب الخدمة مباشرة، ولا تلحق منصة رتب لي أي
                مطالبة من قِبلك، ومع ذلك ستقوم المنصة ببذل المزيد لحماية خدماتك
                من أي تعدٍّ، ولمقدم الخدمة اشتراط السداد قبل تقديمه للمنتجات
                القابلة للتلف أو محدودة الصلاحية.
              </p>
              <p className="body">
                ونحن في منصة رتب لي نذكرك بأن تطبيق المقاييس والمعايير المطلوبة
                والالتزام بالاشتراطات الصحية والبلدية وغيرها تقع على عاتق مقدم
                الخدمة وحده.
              </p>
              <p className="body">
                وتُعدّ العروض المتاحة في صفحتك (نوعها وعددها والوقت المتاح
                لتقديمها) استعداد منك بتقديمها (إيجاب) وبمجرد اختيار المستفيد
                لها وإتمام الطلب (قبول) ويكون العقد &nbsp;تاما بينك وبين طالب
                الخدمة ولا يمكنك التراجع عنه، وبالتالي يتطلب منك الأمر المراجعة
                الدورية لصفحتك في منصة رتب لي بشكل دوري للتأكد من ملاءمة الأسعار
                وجاهزية الخدمة وفقا للمعروض، أما في حالة تعثرك عن تقديم الخدمة
                لأي سبب كان -لا سمح الله- فإنه يتوجب عليك إبلاغ منصة رتب لي فورا
                والتأكد من استلام بلاغك عبر جواب خطي أو بريد إلكتروني، ولمنصة
                رتب لي مطالبتك بدفع غرامة لا تزيد عن قيمة الضرر اللاحق بالعميل
                والمتمثل في تكلفة إيجاد بديل للخدمات المتعثرة في حينها.
              </p>
              <p className="body">
                تخضع خدماتك لتقييم المستفيدين بعد إتمام الخدمة، وتحقيقك لتقييم
                عالٍ يشجع الآخرين على التعامل معك، وإذا تدنى مستوى الخدمة أو
                المنتجات المقدمة من قِبلك لمستوى من التقييم غير مقبول سيتم
                الاستغناء عن خدماتك، وللمنصة استخدام نتيجة تقييم خدماتك من قِبل
                الجمهور عند الحاجة.
              </p>
              <p className="body">
                استفادتك من خدمات منصة رتب لي توجب عليك إبلاغ المنصة بأي محاولة
                نسخ أو قرصنة أو تقليد أو منافسة غير مشروعة للمحتوى في حالة
                اطلاعك عليه.
              </p>
              <p className="body">
                وأنت تلتزم بعدم مطالبة طالب الخدمة بأي أجور إضافية غير المتفق
                عليها مسبقا عبر منصة رتب لي حتى ولو كانت أجرة النقل أو التوصيل
                على سبيل المثال، وللمنصة حق متابعة هذا الإجراء ومراجعة الكشوفات
                والفواتير من تلقاء نفسها أو استجابة لأي شكوى تتلقاها.
              </p>
              <p className="body">
                تنتهج منصة رتب لي سياسة للتسويق وجذب العملاء؛ ومن ضمن هذه
                السياسة تقديم الخصومات والعروض على جميع الخدمات وفقا للخطة
                التسويقية بعد احتساب هامش الربح والتنسيق مع مقدمي الخدمات، وعليه
                يكون التزام من الجميع تجاه هذه السياسة.
              </p>
              <p className="body">
                كما أنك تتعهد بتقديم الخدمة بمباشرتك أو بإشرافك التامّ، دون
                إدخال طرف ثالث ليعمل باسمك أو للتعاقد بالباطن إلا بموافقة خطية
                مسبقة من منصة رتب لي، وتقع كامل المسؤولية في ذلك على مقدم
                الخدمة.
              </p>
              <p className="body">
                في حالة تعذر تقديم الخدمة في أوقات محددة عليك توضيح ذلك للعموم
                عبر الصفحة الخاصة بك في المنصة بما لا يقل عن ثلاثة أيام عمل، وفي
                حالة وجود طلبات مسبقة يتعين عليك الوفاء بها.
              </p>
              <p className="body">
                لدينا اليقين بكفاءتك للعمل مع منصة رتب لي؛ وعليه فأنت تلتزم
                بتقديم خدماتك وفقا للأخلاق والآداب المهنية العالية.
              </p>
              <p className="body">
                كما أنك تتعهد بأنك لن تقوم بتقديم خدماتك بأسعار أقل خارج منصة
                رتب لي، وفي حال رغبتك بتخفيض أسعار الخدمة يتوجب عليك إبلاغ
                المنصة بالأسعار الجديدة قبل إجراء أي تعديلات قبل سبعة أيام من
                التعديل، مع شمولية هذا التعديل للطلبات المحجوزة مسبقا لمواعيد
                قادمة، وفي حال عدم التزامك بهذا الشرط تتعهد بدفع فارق السعر
                للعميل طالب الخدمة.
              </p>
              <p className="body">
                استخدامك للمنصة وتقديم خدماتك من خلالها يوجب عليك دفع رسوم قدرها
                20% من إجمالي قيمة المبيعات، وللمنصة حق الإعفاء من أي رسوم
                كمكافأة أو تشجيع أو أي غرض تراه.
              </p>
              <p className="body">
                وسوف تلتزم بسداد الفواتير المرسلة على بريدك الإلكتروني كل أسبوع
                عبر أحد منافذ السداد المعتمدة من منصة رتب لي خلال سبعة أيام من
                تاريخ الإرسال.
              </p>
              <p className="body">
                لمنصة رتب لي الحق في سحب مستحقاتها وإيداع مستحقاتك وتسوية
                الحسابات من خلال البطاقة الائتمانية الخاصة بك في أي وقت من
                الأوقات، ويُعتبر عدم اعتراضك على أي عملية سحب أو إيداع خلال
                يومين من إجراء العملية موافقة منك على صحة الحسابات الدائنة
                والمدينة.
              </p>
              <p className="body">
                كما أنك على علم بأن طبيعة العلاقة بين منصة رتب لي ومقدم الخدمة
                هي وساطة بعمولة، ولا تعتبر عقد شراكة أو بيع أو إيجار سواء على
                السلع أو الخدمات أو الممتلكات العينية أو النقدية أو الفكرية أو
                غيرها، ولا تعتبر عقد عمل بأي شكل من الأشكال وبالتالي لا تنشأ
                عنها أي حقوق أو اختصاصات عمالية، ولا يغير من طبيعة العلاقة بين
                الطرفين أي مراسلات لاحقة أو سابقة ما لم تنص بشكل صريح على نسخ
                هذا الاتفاق.
              </p>
              <p className="body">
                سرية المعلومات هي الأصل في العلاقة القائمة بين الطرفين، ويحافظ
                مقدم الخدمة على سرية المراسلات والاتفاقات والاتصالات وأي تواصل
                مع منصة رتب لي ، بغض النظر عن محتواها، سواء كانت أمورا فنية أو
                تقنية أو إدارية أو غيرها.
              </p>
              <p className="body">
                في حالة الرغبة بإنهاء التعاقد مع منصة رتب لي يتعين عليك إغلاق
                صفحتك مع إبلاغ الموظف المختص لحذف بياناتك قبل تلقي أي طلبات، وأن
                يكون انسحابك في غير أوقات المواسم [الإجازات-الأعياد-المناسبات
                العامة].
              </p>
            </div>
          </div>
        </section>
      </section>
      <Policy />
    </div>
  );
};

export default PolicyAr;
