import React from "react";
import messages from "../../../assets/local/ar.json";
import { googleApp, appStore } from "../../../config/landing-page-imgs";
import "./downloadApp.scss";
import {Row} from "reactstrap";

const DownloadApp = () => {
  return (
    <section className="section download-app-wrapper">
      <h3 className="section-title text-center mb-3">
        {messages.LANDING_PAGE.DOWNLOAD_APP}
      </h3>
      <div className="row">
        <div className="col-5 mx-auto d-flex justify-content-center">
          <a
            className="download-app-wrapper_btn"
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/ratb-li/id1308032045?ls=1"
          >
            <img
              src={appStore}
              alt="app store"
              className="download-app-wrapper_btn_img"
            />
          </a>
          <a
            className="download-app-wrapper_btn"
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.ITSmart.part"
          >
            <img
              src={googleApp}
              alt="google app"
              className="download-app-wrapper_btn_img"
            />
          </a>
        </div>
      </div>
        <Row className={'justify-content-center align-items-center'}>
            <h4 className={'text-center p-4'}>{messages.LANDING_PAGE.DOWNLOAD_APP_FOOTER}</h4>
        </Row>
    </section>
  );
};

export default DownloadApp
