import React from "react";

const AboutCard = ({ image, title, body }) => {
  return (
    <div className="about-wrapper_card text-center">
      <img src={image} alt="who we are" className="about-wrapper_card_img" />
      <h5 className="about-wrapper_card_title">{title}</h5>
      <div className="about-wrapper_card_body">{body}</div>
    </div>
  );
};

export default AboutCard;
