import React from "react";
import "./featuresCard.scss";

const FeaturesCard = ({ header, title, description, classes }) => {
  return (
    <div className="features-card">
      <h4 className={classes + " features-card_header"}>{header}</h4>
      <h5 className="features-card_title">{title}</h5>
      <p className="features-card_description">{description}</p>
    </div>
  );
};

export default FeaturesCard;
