import React from "react";
import messages from "../../../assets/local/en.json";
import "./styleEn.scss";
import "./style.scss";
import "../styles.scss";
const Policy = () => {
  return (
    <div>
      {/* <hr /> */}
      <section
        className="policy-wrapper 
      policy-en text-left container section default-section-wrapper"
      >
        {" "}
        <h1 className="text-center section-title mb-4">
          Privacy Policy for Ratbli
        </h1>
        <section className="row ">
          <div className="col-md-11 m-auto">
            <h3 className="text-left section-title">
              Collecting and Using Your Personal Data
            </h3>
            <hr />
            <div>
              <h1>Types of Data Collected</h1>
              <h2 className="mt-4">{messages.PRIVACY_POLICY.CO}</h2>
              <h3>Personal Data</h3>
              <p className="body">
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to
              </p>
              <ul>
                <li>
                  <p className="body">Phone number</p>
                </li>
                <li>
                  <p className="body">
                    Address, State, Province, ZIP/Postal code, City
                  </p>
                </li>
                <li>
                  <p className="body">Usage Data</p>
                </li>
              </ul>
            </div>

            <h3 className="mt-3">Usage Data</h3>
            <p className="mt-1 body">
              Usage Data is collected automatically when using the Service
            </p>
            <p className="body">
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data
            </p>
            <p className="body">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </p>
            <p className="body">
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </p>
            <h3 className="mt-3">
              Information Collected while Using the Application
            </h3>
            <p className="body">
              While using Our Application, in order to provide features of Our
              Application, We may collect, with Your prior permission
            </p>
            <ul>
              <li>
                Information regarding your location :
                <p className="body">
                  We use this information to provide features of Our Service, to
                  improve and customize Our Service. The information may be
                  uploaded to the Company's servers and/or a Service Provider's
                  server or it may be simply stored on Your device.
                </p>
                <p className="body">
                  You can enable or disable access to this information at any
                  time, through Your Device settings.
                </p>
              </li>
            </ul>

            <h2>Use of Your Personal Data</h2>
            <p className="body">
              The Company may use Personal Data for the following purposes:
            </p>
            <ul>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    To provide and maintain our Service
                  </span>
                  , including to monitor the usage of our Service.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    To manage Your Account:
                  </span>{" "}
                  to manage Your registration as a user of the Service. The
                  Personal Data You provide can give You access to different
                  functionalities of the Service that are available to You as a
                  registered user.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    For the performance of a contract:
                  </span>{" "}
                  the development, compliance and undertaking of the purchase
                  contract for the products, items or services You have
                  purchased or of any other contract with Us through the
                  Service.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">To contact You:</span>{" "}
                  To contact You by email, telephone calls, SMS, or other
                  equivalent forms of electronic communication, such as a mobile
                  application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">To provide You</span>{" "}
                  with news, special offers and general information about other
                  goods, services and events which we offer that are similar to
                  those that you have already purchased or enquired about unless
                  You have opted not to receive such information.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    To manage Your requests:
                  </span>{" "}
                  To attend and manage Your requests to Us.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    For business transfers:
                  </span>{" "}
                  We may use Your information to evaluate or conduct a merger,
                  divestiture, restructuring, reorganization, dissolution, or
                  other sale or transfer of some or all of Our assets, whether
                  as a going concern or as part of bankruptcy, liquidation, or
                  similar proceeding, in which Personal Data held by Us about
                  our Service users is among the assets transferred.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    For other purposes
                  </span>
                  : We may use Your information for other purposes, such as data
                  analysis, identifying usage trends, determining the
                  effectiveness of our promotional campaigns and to evaluate and
                  improve our Service, products, services, marketing and your
                  experience.
                </p>
              </li>
            </ul>
            <h2>
              We may share Your personal information in the following situations
            </h2>
            <ul>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    With Service Providers:
                  </span>{" "}
                  We may share Your personal information with Service Providers
                  to monitor and analyze the use of our Service, to contact You.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    For business transfers:
                  </span>{" "}
                  We may share or transfer Your personal information in
                  connection with, or during negotiations of, any merger, sale
                  of Company assets, financing, or acquisition of all or a
                  portion of Our business to another company.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">With Affiliates:</span>{" "}
                  We may share Your information with Our affiliates, in which
                  case we will require those affiliates to honor this Privacy
                  Policy. Affiliates include Our parent company and any other
                  subsidiaries, joint venture partners or other companies that
                  We control or that are under common control with Us.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">
                    With business partners:
                  </span>{" "}
                  We may share Your information with Our business partners to
                  offer You certain products, services or promotions.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">With other users:</span>{" "}
                  when You share personal information or otherwise interact in
                  the public areas with other users, such information may be
                  viewed by all users and may be publicly distributed outside.
                </p>
              </li>
              <li>
                <p className="body">
                  <span className="features-card_title">With Your consent</span>
                  : We may disclose Your personal information for any other
                  purpose with Your consent.
                </p>
              </li>
            </ul>
            <h2 className="mt-1">Retention of Your Personal Data</h2>
            <p className="body">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p className="body">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
            <h2>Transfer of Your Personal Data</h2>
            <p className="body">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p className="body">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p className="body">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
            <h2>Delete Your Personal Data</h2>
            <p className="body">
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </p>
            <p className="body">
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </p>
            <p className="body">
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </p>
            <p className="body">
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
            <h2>Disclosure of Your Personal Data</h2>
            <h3>Business Transactions</h3>
            <p className="body">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <h3>Law enforcement</h3>
            <p className="body">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h3>Other legal requirements</h3>
            <p className="body">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
            </ul>
            <h2>Security of Your Personal Data</h2>
            <p className="body">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
            <h2>Children's Privacy</h2>
            <p className="body">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </p>
            <p className="body">
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent's consent before We collect and use that
              information.
            </p>
            <h3>Links to Other Websites</h3>
            <p className="body">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party's site. We strongly advise You to
              review the Privacy Policy of every site You visit.
            </p>
            <p className="body">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <h2>Changes to this Privacy Policy</h2>
            <p className="body">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p className="body">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              &quot;Last updated&quot; date at the top of this Privacy Policy.
            </p>
            <p className="body">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h2>Contact Us</h2>
            <p className="body">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <ul>
              <li>
                <p className="body">
                  By visiting this page on our website:{" "}
                  <a
                    href="https://www.ratbli.sa/"
                    rel="external nofollow noopener"
                    target="_blank"
                  >
                    https://www.ratbli.sa/
                  </a>
                </p>
              </li>
              <li>
                <p className="body">By phone number: 966 0544374444</p>
              </li>
            </ul>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Policy;
