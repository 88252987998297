import React from "react";
import About from "./AboutRatbli/About";
import Features from "./Features/Features";
import Companies from "./CompaniesSection/Companies";
import Header from "./Header/Header";
import "./styles.scss";
import AppReview from "./AppReview/AppReview";
import DownloadApp from "./DownloadApp/DownloadApp";
import ContactUs from "./ContactUs/ContactUs";
import Footer from "./Footer/Footer";

const LandingPage = () => {
  return (
    <div className="landing-page-wrapper">
      <Header />
      <Companies />
      <About />
      <Features />
      <AppReview />
      <DownloadApp />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default LandingPage;
