import React from "react";
import { Col, Label, InputGroup, Input } from "reactstrap";

const FeedbackFormInput = ({
  name,
  label,
  icon,
  placeholder,
  onChange,
  value,
  type,
  options,
  error,
}) => {
  return (
    <Col md="6" className="feedback-wrapper_form_group">
      <Label for={name} className="feedback-wrapper_form_group_label">
        {label}
      </Label>
      <InputGroup className="feedback-wrapper_form_group_input-wrapper">
        {type === "select" ? (
          <Input
            id={name}
            name={name}
            placeholder={placeholder}
            className="feedback-wrapper_form_group_input-wrapper_input"
            value={value}
            onChange={onChange}
            type={type || "text"}
          >
            {type === "select" &&
              options?.map((option) => (
                <option key={option.id} value={option?.name}>
                  {option?.name}
                </option>
              ))}
          </Input>
        ) : (
          <Input
            id={name}
            name={name}
            placeholder={placeholder}
            className="feedback-wrapper_form_group_input-wrapper_input"
            value={value}
            onChange={onChange}
            type={type || "text"}
          />
        )}
      </InputGroup>
      {error && <small className="text-danger">{error}</small>}
    </Col>
  );
};

export default FeedbackFormInput;
