import React, { useEffect } from "react";
import {
  Calendar,
  Home,
  Mail,
  Package,
  Phone,
  ShoppingCart,
  User,
  Users,
} from "react-feather";

import { useFormik } from "formik";
import * as yup from "yup";
import { Button } from "reactstrap";
import { Row } from "reactstrap";
import { logo } from "../../../config/landing-page-imgs";
import "./feedbackForm.scss";
import FeedbackFormInput from "./FeedbackFormInput";
import {
  feedBackAPI,
  getCategories,
  getOccasions,
} from "../../../apis/landing-page";

import messages from "../../../assets/local/ar.json";
import Swal from "sweetalert2";

const FeedbackFrom = (props) => {
  const [occasions, setOccasionsData] = React.useState([]);
  const [needs, setNeeds] = React.useState([]);
  useEffect(() => {
    console.log("kkk");
    getOccasions().then((response) => {
      console.log(response.data.data);
      setOccasionsData(response.data.data);
    });
    getCategories().then((response) => {
      console.log(response.data)
      setNeeds(response.data.data);
    });
  }, []);


  const initialValues = {
    name: "",
    email: "",
    company_name: "",
    phone: "",
    attendance: "",
    occasion: "",
    date: "",
    needs: "",
  };
  const validationSchema = yup.object({
    name: yup
      .string()
      .min(2, messages.ERRORS.MIN_CHAR_2)
      .max(100, messages.ERRORS.MAX_CHAR_100)
      .required(messages.ERRORS.REQUIRED),
    email: yup
      .string()
      .email(messages.ERRORS.INVALID_EMAIL)
      .required(messages.ERRORS.REQUIRED),
    company_name: yup.string().required(messages.ERRORS.REQUIRED),
    phone: yup
      .string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        messages.ERRORS.INVALID_PHONE_NUMBER
      )
      .required(messages.ERRORS.REQUIRED),
    occasion: yup.string().required(messages.ERRORS.REQUIRED),
    attendance: yup
      .number(messages.ERRORS.MUST_BE_NUMBER)
      .required(messages.ERRORS.REQUIRED),
    date: yup.date().required(messages.ERRORS.REQUIRED),
    needs: yup.string().required(messages.ERRORS.REQUIRED),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      feedBackAPI(values)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "تم العملية بنجاح",
            timer: 2500,
          });
          formik.resetForm();
          props.setOpen(false)
        })
        .catch((err) => {
          console.log(JSON.stringify(err.response.data.message));
          Swal.fire({
            icon: "error",
            title: "حدث خطأ ما",
            text: JSON.stringify(err.response?.data?.message),
          });
        });
    },
  });

  return (
    <section className="container feedback-wrapper">
      <header className="feedback-wrapper_header">
        <img src={logo} alt="logo" className="feedback-wrapper_header_img" />
        <h3 className="feedback-wrapper_header_title col-md-6 mx-auto">
          {messages.LANDING_PAGE.FEEDBACK.TITLE}
        </h3>
      </header>
      <form
        onSubmit={formik.handleSubmit}
        className="feedback-wrapper_form container"
      >
        <Row className="w-100">
          <FeedbackFormInput
            name="name"
            label={messages.LANDING_PAGE.CONTACT_US.FORM.NAME}
            placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.NAME}
            icon={<User />}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.errors.name}
          />
          <FeedbackFormInput
            name="email"
            label={messages.LANDING_PAGE.CONTACT_US.FORM.EMAIL}
            placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.EMAIL}
            icon={<Mail />}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.errors.email}
          />
        </Row>
        <Row className="w-100">
          <FeedbackFormInput
            name="company_name"
            label={messages.LANDING_PAGE.FEEDBACK.COMPANY_NAME}
            placeholder={messages.LANDING_PAGE.FEEDBACK.COMPANY_NAME}
            icon={<Home />}
            onChange={formik.handleChange}
            value={formik.values.company_name}
            error={formik.errors.company_name}
          />
          <FeedbackFormInput
            name="phone"
            label={messages.LANDING_PAGE.CONTACT_US.FORM.PHONE}
            placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.PHONE}
            icon={<Phone />}
            onChange={formik.handleChange}
            value={formik.values.phone}
            error={formik.errors.phone}
          />
        </Row>
        <Row className="w-100">
          {console.log({ occasions })}
          <FeedbackFormInput
            name="occasion"
            label={messages.LANDING_PAGE.FEEDBACK.OCCASION}
            placeholder={messages.LANDING_PAGE.FEEDBACK.OCCASION}
            icon={<Package />}
            onChange={formik.handleChange}
            value={formik.values.occasion}
            type="select"
            options={occasions}
            error={formik.errors.occasion}
          />
          <FeedbackFormInput
            name="attendance"
            label={messages.LANDING_PAGE.FEEDBACK.ATTENDANCE_NUMBER}
            placeholder={messages.LANDING_PAGE.FEEDBACK.ATTENDANCE_NUMBER}
            icon={<Users />}
            onChange={formik.handleChange}
            value={formik.values.attendance}
            type="text"
            error={formik.errors.attendance}
          />
        </Row>
        <Row className="w-100">
          <FeedbackFormInput
            name="date"
            label={messages.LANDING_PAGE.FEEDBACK.DATE}
            placeholder={messages.LANDING_PAGE.FEEDBACK.DATE}
            icon={<Calendar />}
            onChange={formik.handleChange}
            value={formik.values.date}
            type="date"
            error={formik.errors.date}
          />

          <FeedbackFormInput
            name="needs"
            label={messages.LANDING_PAGE.FEEDBACK.NEEDS}
            placeholder={messages.LANDING_PAGE.FEEDBACK.NEEDS}
            icon={<ShoppingCart />}
            onChange={formik.handleChange}
            value={formik.values.needs}
            type="select"
            options={needs}
            error={formik.errors.needs}
          />
        </Row>
        <Button
          type="submit"
          className="feedback-wrapper_form_btn"
          // onClick={() => setOpen(!open)}
        >
          {messages.LANDING_PAGE.CONTACT_US.FORM.SEND}
        </Button>{" "}
      </form>
    </section>
  );
};

export default FeedbackFrom;
