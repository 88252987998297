import React from "react";
import { PlayCircle } from "react-feather";
import { Col, Row } from "reactstrap";
import messages from "../../../assets/local/ar.json";
import { video, idCard, eye, connection } from "../../../config/landing-page-imgs";
import "./about.scss";
import AboutCard from "./AboutCard/AboutCard";

const About = () => {
  return (
    <>
      <section className="container section default-section-wrapper" >
        <section className="row align-items-center">
          <div className="default-section-wrapper_content col-md-6 px-4">
            <h3 className="section-title">
              {messages.LANDING_PAGE.ABOUT.TITLE}
            </h3>
            <p className="default-section-wrapper_content_body mt-2">
              {messages.LANDING_PAGE.COMPANIES.BODY}
            </p>
          </div>
          <div className="col-md-6 default-section-wrapper_img-wrapper" id="overview">
            <img
              className="default-section-wrapper_img-wrapper_img"
              src={video}
              alt="video"
            />
            <a
              href="https://ratbli-uploads.s3.eu-central-1.amazonaws.com/videos/RevsBfiA5Uv8bxUKklXyiiawrmMeTNAT3oSj56rG.mp4"
              className="btn-play"
              target="_blank"
              rel="noreferrer"
            >
              <PlayCircle className="btn-play_icon" />
            </a>
          </div>
        </section>
      </section>
      <section className="container section about-wrapper px-3" id="about">
        <h3 className="section-title text-center">
          {messages.LANDING_PAGE.NAV.ABOUT_RATBLI}
        </h3>
        <Row>
          <Col md="4">
            <AboutCard
              image={idCard}
              title={messages.LANDING_PAGE.ABOUT.WHO_WE_ARE_TITLE}
              body={messages.LANDING_PAGE.ABOUT.WHO_WE_ARE_BODY}
            />
          </Col>
          <Col md="4">
            <AboutCard
              image={connection}
              title={messages.LANDING_PAGE.ABOUT.OUR_VISION_TITLE}
              body={messages.LANDING_PAGE.ABOUT.OUR_VISION_BODY}
            />
          </Col>
          <Col md="4">
            <AboutCard
              image={eye}
              title={messages.LANDING_PAGE.ABOUT.OUR_PRINCIPLES_TITLE}
              body={
                <>
                  <p className="mb-0">
                    {messages.LANDING_PAGE.ABOUT.OUR_PRINCIPLES_BODY1}
                  </p>
                  <p className="mb-0">
                    {messages.LANDING_PAGE.ABOUT.OUR_PRINCIPLES_BODY2}
                  </p>
                  <p className="mb-0">
                    {messages.LANDING_PAGE.ABOUT.OUR_PRINCIPLES_BODY3}
                  </p>
                </>
              }
            />
          </Col>
        </Row>
      </section>
      <section className="container section about-wrapper px-3 mb-5" id="team">
        <div className="default-section-wrapper_content col-md-6 px-4">
          <h3 className="section-title">
            {messages.LANDING_PAGE.TEAM.TITLE}
          </h3>
          <p className="default-section-wrapper_content_body mt-2">
            {messages.LANDING_PAGE.TEAM.BODY}
          </p>
        </div>
      </section>
    </>
  );
};

export default About
