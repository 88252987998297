import React from "react";
import messages from "../../../assets/local/ar.json";
import { Row, Col } from "reactstrap";
import "./appReview.scss";
import { app1, app2, app3 } from "../../../config/landing-page-imgs";
const AppReview = () => {
  return (
    <section className="container section app-review-wrapper">
      <h3 className="section-title text-center mb-3">
        {messages.LANDING_PAGE.VIEW_APP}
      </h3>
      <Row>
        <Col md="6" className="mx-auto">
          <Row>
            <Col md="4">
              <img src={app1} alt="app1" />
            </Col>
            <Col md="4">
              <img src={app2} alt="app2" />
            </Col>
            <Col md="4">
              <img src={app3} alt="app3" />
            </Col>
          </Row>
            <Row className={'align-items-center justify-content-center'}>
                <h4 className={'text-center p-4'}>{messages.LANDING_PAGE.VIEW_APP_FOOTER}</h4>
            </Row>
        </Col>
      </Row>
    </section>
  );
};

export default AppReview
