import { axiosInstance } from "./index";

export const registerLanding = async ({
  name,
  phone,
  email,
  password,
  store_name_ar,
  store_name_en,
  store_main_picture,
}) => {
  let formData = new FormData();

  let image = store_main_picture;
  formData.append("store_main_picture", image);

  return await axiosInstance.post(`/auth/register_landing`, formData, {
    params: {
      name,
      email,
      phone,
      password,
      "store_name[ar]": store_name_ar,
      "store_name[en]": store_name_en,
    },
  });
};

export const contactUsAPI = async ({ name, phone, email, message }) => {
  let formData = new FormData();

  return await axiosInstance.post(`/general/contact_us`, formData, {
    params: {
      name,
      phone,
      email,
      message,
    },
  });
};

export const feedBackAPI = async ({
  name,
  phone,
  email,
  company_name,
  attendance,
  occasion,
  date,
  needs,
}) => {
  let formData = new FormData();
  return await axiosInstance.post(`/general/feedback`, formData, {
    params: {
      name,
      phone,
      email,
      company_name,
      attendance,
      occasion,
      date,
      needs,
    },
  });
};

export const getOccasions = async () => {
  return await axiosInstance.get(`/occasions`);
};

export const getCategories = async () => {
  return await axiosInstance.get(`/sub_categories`);
};