import React from "react";
import messages from "../../../assets/local/ar.json";
import { Form, FormGroup, Input } from "reactstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import "./contactUs.scss";
import { contactUsAPI } from "../../../apis/landing-page";
import Swal from "sweetalert2";

const ContactUs = () => {
  const initialValues = { name: "", email: "", phone: "", message: "" };
  const validationSchema = yup.object({
    name: yup
      .string()
      .min(2, messages.ERRORS.MIN_CHAR_2)
      .max(100, messages.ERRORS.MAX_CHAR_100)
      .required(messages.ERRORS.REQUIRED),
    email: yup
      .string()
      .email(messages.ERRORS.INVALID_EMAIL)
      .required(messages.ERRORS.REQUIRED),
    phone: yup
      .string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        messages.ERRORS.INVALID_PHONE_NUMBER
      )
      .required(messages.ERRORS.REQUIRED),
    message: yup.string().required(messages.ERRORS.REQUIRED).min(8),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      contactUsAPI(values)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "تم العملية بنجاح",
            timer: 2500,
          });
          formik.resetForm();
        })
        .catch((err) => {
          console.log(JSON.stringify(err.response.data.message));
          Swal.fire({
            icon: "error",
            title: "حدث خطأ ما",
            text:JSON.stringify(err.response?.data?.message)
          });
        });
    },
  });

  return (
    <section className="container contact-us-wrapper" id="contact-us">
      <div className="contact-us-wrapper_header">
        <h3 className="section-title text-center mb-2">
          {messages.LANDING_PAGE.CONTACT_US.TITLE}
        </h3>
        <p>{messages.LANDING_PAGE.CONTACT_US.DESCRIPTION}</p>
      </div>
      <Form
        onSubmit={formik.handleSubmit}
        className="col-md-6 mx-auto contact-us-wrapper_form"
      >
        <FormGroup>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.NAME}
            className="contact-us-wrapper_form_input"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name && (
            <small className="text-danger">{formik.errors.name}</small>
          )}
        </FormGroup>
        <FormGroup>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.EMAIL}
            className="contact-us-wrapper_form_input"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && (
            <small className="text-danger">{formik.errors.email}</small>
          )}
        </FormGroup>
        <FormGroup>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.PHONE}
            className="contact-us-wrapper_form_input"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
          {formik.errors.phone && (
            <small className="text-danger">{formik.errors.phone}</small>
          )}
        </FormGroup>
        <FormGroup>
          <Input
            type="textarea"
            name="message"
            id="message"
            placeholder={messages.LANDING_PAGE.CONTACT_US.FORM.HOW_CAN_WE_HELP}
            className="contact-us-wrapper_form_input"
            rows="4"
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          {formik.errors.message && (
            <small className="text-danger">{formik.errors.message}</small>
          )}
        </FormGroup>
        <button
          type="submit"
          className="contact-us-wrapper_form_submit-btn mx-auto"
        >
          {messages.LANDING_PAGE.CONTACT_US.FORM.SEND}
        </button>
      </Form>
    </section>
  );
};

export default ContactUs;
